body {
  margin: 0;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

:root {
  font-size: clamp(7px, calc(7px + (18 - 7) * ((100vw - 400px) / (1200 - 320))), 18px);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landing-page {
  --landing-base-size: clamp(12px, calc(10px + (24 - 12) * ((100vw - 300px) / (1600 - 300))), 24px);
  --landing-h2-size: calc(var(--landing-base-size) * 1.5);
  --landing-h4-size: calc(var(--landing-base-size) * 1.2);
  --landing-p-size: var(--landing-base-size);
  --landing-li-size: calc(var(--landing-base-size) * 0.9);
}

.landing-page h2 {
  font-size: var(--landing-h2-size);
}

.landing-page h4 {
  font-size: var(--landing-h4-size);
}

.landing-page p {
  font-size: var(--landing-p-size);
}

.landing-page li {
  font-size: var(--landing-li-size);
}

.landing-page .lead {
  font-size: calc(var(--landing-base-size) * 1.2);
}